import jwt_decode from "jwt-decode";
import detectEthereumProvider from "@metamask/detect-provider";
import React, { createContext, useContext, useEffect, useState } from "react";
import { QUERY_USER } from "../graphql-operations/queryUsers";
import {
  useCreateUserMutation,
  useSignInUserMutation,
  useUserQuery
} from "../generated/graphql_schema";
import { appClient } from "../apollo-client";
import { BoxColumn } from "../components/common/Boxes";
import { CircularProgress, Typography } from "@mui/material";
import router, { useRouter } from "next/router";
import { useApolloClient } from "@apollo/client";
import {
  isDevelopment,
  chainId as chainIdConfig,
  chainIdHex,
  chainName as chainNameConfig,
  chainRPC,
  CHAIN_ID_LOCAL
} from "../config";

export interface IAuthenticationContext {
  signIn: () => void;
  signOut: () => void;
  errorMessage: string;
  user?: IUser;
  isLoading: boolean;
  chainId?: number;
}

export const AuthenticationContext = createContext<IAuthenticationContext>({
  signIn: () => {},
  signOut: () => {},
  errorMessage: "",
  isLoading: false
});

export interface IUser {
  ensName?: string;
  publicAddress: string;
  id: number;
}

interface IDecodedJwt {
  payload: { id: number; publicAddress: string; ensName?: string };
  exp: number;
  iat: number;
}

export const AuthenticationProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [metamaskAccount, setMetamaskAccount] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [signInUserMutation] = useSignInUserMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [chainId, setChainId] = useState<number>();
  const [user, setUser] = useState<IUser>();
  const apolloClient = useApolloClient();

  const [createUserMutation] = useCreateUserMutation();

  async function enableMetamask() {
    if (window.ethereum) {
      const account = await getMetamaskAccount();
      setMetamaskAccount(account);
      return account;
    }
  }

  useEffect(() => {
    async function subscribeChainChanged() {
      //@ts-ignore
      window.ethereum.on(
        "chainChanged",
        function onChainChanged(chainId: string) {
          window.location.reload();
          const chainIdNum = parseInt(chainId, 16);
          setChainId(chainIdNum);
        }
      );
    }

    async function subscribeAccountsChanged() {
      //@ts-ignore
      window.ethereum.on(
        "accountsChanged",
        function onAccountsChanged(accounts: string[]) {
          setMetamaskAccount(accounts[0] || "");
          signOut();
        }
      );
    }

    async function getChainId() {
      //@ts-ignore
      const chainId = await window.ethereum.request({ method: "eth_chainId" });
      const chainIdNum = parseInt(chainId, 16);
      return chainIdNum;
    }

    async function connectMetamask() {
      await enableMetamask();
      const chainId = await getChainId();

      setChainId(chainId);
      // if (isDevelopment && chainId !== CHAIN_ID_LOCAL) {
      //   setErrorMessage("Please connect to the hardhat local network");
      // }
    }

    async function signInFromLocalJwt() {
      const jwt = localStorage.getItem("jwt");

      if (jwt) {
        const decodedJwt = jwt_decode(jwt) as IDecodedJwt;
        const isJwtExpired = calcIsJwtExpired(decodedJwt.exp);

        if (isJwtExpired) {
          localStorage.removeItem("jwt");
        } else {
          setUser(decodedJwt.payload);
        }
      }
    }

    if (window.ethereum) {
      connectMetamask();
      subscribeChainChanged();
      subscribeAccountsChanged();
      signInFromLocalJwt();
    } else {
      setErrorMessage(
        "Please install metamask through the extension or mobile app"
      );
    }
  }, []);

  const signOut = () => {
    appClient.clearStore().then(() => {
      appClient.resetStore();
      router.push("/");
      localStorage.clear();
      setUser(undefined);
    });
  };

  const createAccount = async (account = metamaskAccount) => {
    const { data } = await createUserMutation({
      variables: { publicAddress: account }
    });

    return data?.createUser?.nonce;
  };

  const signInWithNonce = async (nonce: number) => {
    setIsLoading(true);

    const isIncorrectChainId = chainId !== chainIdConfig;

    // if (!isDevelopment && isIncorrectChainId) {
    if (isIncorrectChainId) {
      await requestChangeChain();
    }

    // sign message with nonce
    let signature = "";
    try {
      signature = (await handleSignMessage(metamaskAccount, nonce)) || "";
    } catch (e) {
      console.error("Metamask sign in error: ", e);
      setIsLoading(false);
      router.push("/");
      return;
    }

    const signInUserResponse = await signInUserMutation({
      variables: { signature, publicAddress: metamaskAccount }
    });

    const jwt = signInUserResponse.data?.signInUser?.jwt;

    // store jwt
    if (jwt) {
      const decodedJwt = jwt_decode(jwt) as IDecodedJwt;
      localStorage.setItem("jwt", jwt);
      setUser(decodedJwt.payload);
    }

    setIsLoading(false);
  };

  const signIn = async () => {
    let account = metamaskAccount;

    setIsLoading(true);

    if (!metamaskAccount) {
      const newAccount = await enableMetamask();
      if (newAccount) {
        account = newAccount;
      }
    }

    if (!account) {
      setErrorMessage("No metamask connection found");
      return;
    }

    const userData = await apolloClient.query({
      query: QUERY_USER,
      variables: { publicAddress: account }
    });

    const userNonce = userData?.data?.user?.nonce;

    if (userNonce) {
      signInWithNonce(userData.data.user.nonce);
    } else {
      const nonce = await createAccount(account);
      if (nonce) {
        signInWithNonce(nonce);
      }
    }
  };

  return (
    <AuthenticationContext.Provider
      value={{
        chainId,
        signIn,
        signOut,
        errorMessage,
        user,
        isLoading
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

const handleSignMessage = async (publicAddress: string, nonce: number) => {
  const provider = (await detectEthereumProvider()) as any;

  if (!provider) return console.error("No provider found");

  const data = JSON.stringify({
    domain: {
      chainId: chainIdConfig,
      name: "Runes Duel",
      version: "1"
    },
    message: {
      "Verification Message":
        "Please sign this message to log in with this one-time nonce: " + nonce
    },
    // Refers to the keys of the *types* object below.
    // not sure why this is needed
    primaryType: "Verification",
    types: {
      Verification: [{ name: "Verification Message", type: "string" }],
      EIP712Domain: [
        { name: "name", type: "string" },
        { name: "version", type: "string" },
        { name: "chainId", type: "uint256" }
      ]
    }
  });

  const from = publicAddress;
  const params = [from, data];

  return new Promise<string>((resolve, reject) =>
    provider.sendAsync(
      {
        method: "eth_signTypedData_v4",
        params,
        from
      },
      function (err: any, result: any) {
        if (err) return reject(err);
        if (result.error) {
          return reject(result.error.message);
        }
        resolve(result.result);
      }
    )
  );
};

export const useAuth = () => useContext(AuthenticationContext);

export const ProtectRoute: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const { isLoading, user } = useAuth();
  const router = useRouter();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!isLoading && !user) {
      timer = setTimeout(() => {
        router.push("/");
      }, 5000);
    }
    return () => {
      if (timer !== undefined) {
        clearInterval(timer);
      }
    };
  }, [isLoading, user]);

  if (isLoading) {
    return <LoadingScreen />;
  } else if (!user) {
    return (
      <BoxColumn
        sx={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%"
        }}
      >
        <Typography variant="h5">
          You must be signed in to access this page. Redirecting...
        </Typography>
      </BoxColumn>
    );
  }
  return <>{children}</>;
};

const LoadingScreen = () => (
  <BoxColumn
    sx={{
      width: "100%",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
      gap: 1
    }}
  >
    <CircularProgress />
    <Typography variant="h6">Loading</Typography>
  </BoxColumn>
);

// export const withProtectedRoute = (Route: React.FC) => (props: any) =>
//   <ProtectRoute>{<Route {...props} />}</ProtectRoute>;
export function withProtectedRoute(Route: React.FC) {
  return function inner(props: any) {
    const { isLoading, user } = useAuth();
    const router = useRouter();

    useEffect(() => {
      let timer: NodeJS.Timeout;
      if (!isLoading && !user) {
        timer = setTimeout(() => {
          router.push("/");
        }, 3000);
      }
      return () => {
        if (timer !== undefined) {
          clearInterval(timer);
        }
      };
    }, [isLoading, user]);

    if (isLoading) {
      return <LoadingScreen />;
    } else if (!user) {
      return (
        <BoxColumn
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%"
          }}
        >
          <Typography variant="h5">
            You must be signed in to access this page. Redirecting...
          </Typography>
        </BoxColumn>
      );
    }

    return <Route {...props} />;
  };
}

const getMetamaskAccount = async () => {
  const accounts = await getAccounts();
  return accounts[0];
};
async function getAccounts(): Promise<string[]> {
  const provider = (await detectEthereumProvider()) as any;

  const accounts = await provider.request({
    method: "eth_requestAccounts"
  });
  return accounts;
}

const calcIsJwtExpired = (exp: number) => {
  // given exp time in seconds from backend convert to milliseconds for calculations
  const expMs = exp * 1000;
  const dateNowUTC = Date.now();
  return dateNowUTC >= expMs;
};

const requestChangeChain = async () => {
  const provider = (await detectEthereumProvider()) as any;
  try {
    await provider.request({
      method: "wallet_switchEthereumChain",
      params: [
        {
          chainId: chainIdHex
        }
      ]
    });
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if ((switchError as any).code === 4902) {
      try {
        await provider.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: chainIdHex,
              chainName: chainNameConfig,
              rpcUrls: [chainRPC]
            }
          ]
        });
      } catch (addError) {
        // handle "add" error
      }
    }
    // handle other "switch" errors
  }
};
