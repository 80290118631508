import { Button, ButtonProps } from "@mui/material";
import Link from "next/link";

interface ILinkButtonProps extends ButtonProps {
  href: string;
  children: React.ReactNode;
}

export const LinkButton: React.FC<ILinkButtonProps> = ({
  children,
  href,
  ...buttonProps
}) => {
  return (
    <Link href={href} passHref>
      {/* @ts-ignore */}
      <Button component="a" {...buttonProps}>
        {children}
      </Button>
    </Link>
  );
};

// <Link href="/lobby/create" passHref>
//   <Button component="a" >
//     search lobbies
//   </Button>
// </Link>
