"use client";
import {
  Button,
  Typography,
  Box,
  Divider,
  Menu,
  MenuItem,
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  Link,
  useMediaQuery,
  IconButton,
  Drawer
} from "@mui/material";
import { Menu as MenuIcon, Twitter } from "@mui/icons-material";

import { AuthenticationContext, useAuth } from "../context/Authentication";
import React, { useContext, useEffect, useState } from "react";
import { BoxColumn } from "./common/Boxes";
import { shortenAddress } from "./common/util";
import { useRouter } from "next/router";
import LinkNext from "next/link";
import { Footer, SocialIcons } from "./Footer";
import { useIsUserAdminQuery } from "../generated/graphql_schema";
import { LinkButton } from "./common/LinkButton";

const tabsSignedIn = [
  {
    label: "collection",
    href: "/collection"
  },
  {
    label: "free mint",
    href: "/mint"
  }
];

const adminTabs = [
  {
    label: "admin",
    href: "/admin"
  }
];

const tabs = [
  {
    label: "what is runes?",
    href: "/whatisrunes"
  },
  {
    label: "tutorial",
    href: "/tutorial"
  },
  {
    label: "card packs",
    href: "/card-packs"
  },
  {
    label: "tournaments",
    href: "/tournaments"
  },
  {
    label: "leaderboard",
    href: "/leaderboard"
  },
  {
    label: "play",
    href: "/lobby"
  }
];

const Header = () => {
  const { user, signOut } = useAuth();
  const isMobileView = useMediaQuery("(max-width:600px)");
  const router = useRouter();
  const [tab, setTab] = useState<false | string>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { data, loading, error, refetch } = useIsUserAdminQuery();
  const [userTabs, setUserTabs] = useState(tabs);

  useEffect(() => {
    if (user) {
      setUserTabs(tabs.concat(tabsSignedIn));
    } else {
      setUserTabs(tabs);
    }

    if (data?.isUserAdmin) {
      setUserTabs((u) => u.concat(adminTabs));
    }
  }, [user, data]);

  useEffect(() => {
    if (router.pathname === "/") {
      setTab(false);
    } else {
      setTab(router.pathname);
    }
  }, [router.pathname]);

  const SignOutButton = (
    <Button
      color="secondary"
      onClick={signOut}
      sx={{ whiteSpace: "nowrap", fontSize: 14 }}
    >
      Sign Out
    </Button>
  );

  const tabValue =
    userTabs.findIndex((tab) => tab.href === router.pathname) ?? false;

  return (
    <AppBar
      position="static"
      style={{ background: "transparent", boxShadow: "none" }}
    >
      <Toolbar
        sx={
          isMobileView
            ? {
                display: "flex",
                justifyContent: "space-between"
              }
            : undefined
        }
      >
        <Typography variant="h4" sx={{ mr: 1 }}>
          <LinkNext href="/">
            <FlameLink />
          </LinkNext>
        </Typography>

        {!isMobileView && (
          <>
            <Tabs
              // value={tab}
              value={tabValue}
              sx={{
                flex: "1 1 300px",
                display: "flex",
                alignItems: "center"
              }}
              scrollButtons="auto"
              variant="scrollable"
            >
              {userTabs.map((tab, i) => (
                <LinkTab key={tab.href} tab={tab} value={i} />
              ))}

              {/* {!user && router.pathname !== "/" && (
                <LinkTab tab={{ label: "sign in", href: "/" }} />
              )} */}
            </Tabs>

            {!user && router.pathname !== "/" && (
              <LinkButton href="/">sign in</LinkButton>
            )}
          </>
        )}

        {user && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 1
            }}
          >
            {user.ensName && (
              <Typography
                variant="body1"
                sx={{
                  mr: 1,
                  p: 1,
                  borderRadius: 1
                }}
              >
                {user.ensName}
              </Typography>
            )}
            {!user.ensName && user.publicAddress && (
              <Typography
                variant="body1"
                sx={{
                  mr: 1,
                  p: 1,
                  borderRadius: 1
                }}
              >
                {shortenAddress(user.publicAddress)}
              </Typography>
            )}

            {!isMobileView && SignOutButton}
          </Box>
        )}

        {isMobileView && (
          <>
            <Drawer
              anchor="right"
              open={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}
              PaperProps={{
                sx: {
                  background: "#000"
                }
              }}
              //   sx={{ background: "transparent" }}
            >
              <BoxColumn
                sx={{
                  //   width: "fit-content",
                  maxWidth: "100%",
                  height: "100%",
                  textAlign: "center",
                  p: 2
                }}
              >
                <Tabs
                  value={tab}
                  sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}
                  orientation="vertical"
                >
                  {userTabs.map((tab, i) => (
                    <LinkTab key={tab.href} tab={tab} value={i} />
                  ))}

                  {user && (
                    <Tab
                      key="signout"
                      onClick={signOut}
                      label="Sign Out"
                      value="signout"
                    />
                  )}

                  {!user && router.pathname !== "/" && (
                    <LinkTab
                      tab={{ label: "sign in", href: "/" }}
                      value={userTabs.length + 1}
                    />
                  )}
                </Tabs>

                <SocialIcons />
              </BoxColumn>
            </Drawer>
            <IconButton onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
              <MenuIcon sx={{ color: "white", fontSize: 32 }} />
            </IconButton>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

// has Header and Footer
export const AppLayout = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const isInLobby = router.pathname.startsWith("/lobby/");
  const isInHome = router.pathname === "/";
  const isMobileView = useMediaQuery("(max-width:600px)");

  return (
    <BoxColumn
      sx={{
        width: "100%",
        height: "100%",
        justifyContent: isInHome && !isMobileView ? "space-between" : undefined
      }}
    >
      {!isInLobby && <Header />}
      {children}
      {isInHome && !isMobileView && <Footer />}
    </BoxColumn>
  );
};

const LinkTab = ({
  tab,
  value
}: {
  tab: { label: string; href: string };
  value: number;
}) => {
  return (
    // <Tab
    //   key={tab.href}
    //   LinkComponent={(props) => (
    //     <LinkNext href={props.href}>
    //       <a
    //         style={{
    //           marginBottom: 8,
    //           padding: 8,
    //           fontSize: 18
    //         }}
    //       >
    //         {tab.label}
    //       </a>
    //     </LinkNext>
    //   )}
    //   href={tab.href}
    //   value={tab.href}
    // />

    <Tab
      key={tab.href}
      component={React.forwardRef<
        HTMLAnchorElement,
        { href: string; children: React.ReactNode }
      >(function Comp(props, ref) {
        return (
          <LinkNext href={props.href}>
            <a
              ref={ref}
              style={{
                marginRight: 16,
                padding: 8,
                color: "white"
              }}
            >
              {props.children}
              {tab.label}
            </a>
          </LinkNext>
        );
      })}
      href={tab.href}
      value={tab.href}
    />
  );
};

import { useSpring, animated, config } from "react-spring";

const FlameLink = () => {
  const [props, set] = useSpring(() => ({
    backgroundPosition: "0% 0%",
    config: config.molasses
  }));
  const router = useRouter();

  useEffect(() => {
    const interval = setInterval(() => {
      set({
        backgroundPosition: `${Math.random() * 100}% 0%`
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [set]);

  const flameAnimation = {
    background: `linear-gradient(90deg, red, orange, yellow, orange, red)`,
    backgroundSize: "500% auto",
    WebkitBackgroundClip: "text",
    backgroundClip: "text",
    color: "transparent",
    textShadow: "2px 2px 4px rgba(255, 105, 0, 0.8)",
    cursor: "pointer"
  };

  return (
    <animated.a
      style={{ ...flameAnimation, ...props }}
      onClick={() => {
        router.push("/");
      }}
    >
      Runes
    </animated.a>
  );
};
